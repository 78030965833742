.App {
  font-family: "Roboto-Black";
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:#992288;     /*#282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: "Black";   /*Can be any text*/
  src: local("Roboto-Black"),
    url("./fonts/Roboto-Black.ttf") format("truetype");
}
@font-face {
  font-family: "BlackItalic";   /*Can be any text*/
  src: local("Roboto-BlackItalic"),
    url("./fonts/Roboto-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("Roboto-Bold"),
    url("./fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BoldItalic";   /*Can be any text*/
  src: local("Roboto-BoldItalic"),
    url("./fonts/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Italic";   /*Can be any text*/
  src: local("Roboto-Italic"),
    url("./fonts/Roboto-Italic.ttf") format("truetype");
}


@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("Roboto-Light"),
    url("./fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "LightItalic";   /*Can be any text*/
  src: local("Roboto-LightItalic"),
    url("./fonts/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("Roboto-Medium"),
    url("./fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "MediumItalic";   /*Can be any text*/
  src: local("Roboto-MediumItalic"),
    url("./fonts/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("Roboto-Regular"),
    url("./fonts/Roboto-Regular.ttf") format("truetype");
}






@font-face {
  font-family: "Thin";   /*Can be any text*/
  src: local("Roboto-Thin"),
    url("./fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "ThinItalic";   /*Can be any text*/
  src: local("Roboto-ThinItalic"),
    url("./fonts/Roboto-ThinItalic.ttf") format("truetype");
}

