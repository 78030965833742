@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@300&family=Lora:wght@400;700&family=Roboto+Slab:wght@200;300;400;500;&display=swap');

body {
  font-family: 'Lora', serif;
  font-size:14;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5 {
  font-family: 'Lora', serif;
  font-weight:900;
}

h1 {
  font-size:32px;
}

h2 {
  font-size:28px;
}
h3 {
  font-size:24px;
}
h4 {
  font-size:22px;
}
h5 {
  font-size:20px;
}

h6 {
  font-size:20px;
}

textarea {
  font-family: 'Lora', serif;
}

pre {
  font-family: 'Lora', serif;
  padding-left:0;
  padding-top:0;
  padding-bottom:0;
  padding-right:0;
  margin:0;
  font-size: 15px;
  overflow: hidden;
  white-space: pre-wrap;       
  word-wrap: break-word;
  background-color:transparent;
}



button {
  font-family: 'Lora', serif;
  color:#232323;
  border-color:#232323;
  background-color:#FFFFFF;
  border-width:2px;
  border-radius:8px;
}

.button {
  color:#232323;
  border-color:#232323;
  background-color:#FFFFFF;
  border-width:2px;
  border-radius:8px;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width:1000; 
}

.rteToolbar {
  border: none;
  color: transparent;
}

.markdown > * {
  all: revert;
}


